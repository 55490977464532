<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
        </div>

        <div class="col-lg-5 flex-between">
          <div class=""></div>

          <div class="login-box">
            <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="">
            <h5>{{ $t('mfa.mfaVerification') }}</h5>

            <p>{{ $t('mfa.verificationDevices') }}</p>

            <table>
              <tbody>
                <tr v-for="option of mfaOptions" :key="option.type">
                  <td>
                    <p class="no-wrap">
                      <strong>{{ option.value }}</strong>
                      <span>({{ option.type }})</span>
                    </p>
                  </td>
                  <td>
                    <button class="btn btn-outline-secondary" :data-test="`btnMfaCode_${option.type}`" @click="sendMFACode(option.type)">
                      {{ $t('mfa.sendCode') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <img class="aws-logo" src="@/assets/images/erjart/logo_aws.svg" alt="Logo AWS">
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>

    <teleport to="body">
      <UserMfaRegisterOffcanvas :offcanvas_id="'userMfaRegisterOffcanvas'" ref="userMfaRegisterOffcanvas"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.login-page {
  padding:                                 8px;
  height:                                  100vh;
  .container {
    height:                                100%;
    max-width:                             unset !important;
  }
  .row {
    height:                                100%;
  }
  .col-lg-7 {
    @include                               border_radius(10px);
    display:                               flex;
    flex-direction:                        column;
    justify-content:                       space-between;
    background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
    // background:                            url('@/assets/images/phelcom/eyer2_number.png') no-repeat right center, linear-gradient(252deg, #000 0%, #0D4636 100%);
    padding:                               5rem 3rem 3rem;
  }

  .col-lg-5 {
    flex-direction:                        column;
    .login-box {
      width:                               70%;
      max-width:                           400px;
      p {
        font-size:                         var(--font-size-14);
        font-weight:                       400;
        line-height:                       normal;
        letter-spacing:                    0.7px;
        text-align:                        center;
        margin:                            1rem 0;
      }
      h5 {
        color:                             var(--Brand-Primary, #1B294A);
        font-size:                         var(--font-size-22);
        font-weight:                       300;
        margin:                            1.8rem 0;
      }
      p {
        color:                             var(--Shades-90, #101010);
        margin-top:                        1.5rem;
      }

      table {
        tbody {
          tr {
            td {
              padding:                     5px 5px 5px 0;
              p {
                margin:                    0;
              }
              .btn-outline-secondary {
                margin-left:               10px;
              }
            }
          }
        }
      }
    }
    .powered-box {
      display:                             flex;
      justify-content:                     flex-end !important;
      align-items:                         center;
      p {
        color:                             var(--Brand-Primary, #1B294A);
        font-size:                         var(--font-size-10);
        font-weight:                       400;
        letter-spacing:                    2.15px;
        margin:                            0;
      }
      .logo {
        color:                             #1B294A;
        width:                             101px;
        // font-size:                         1.25em;
        // margin:                            0 15px;
        margin:                            0;
        text-decoration:                   none;
      }
    }
  }
}

// @media (max-width: 550px) {
//   .login-page {
//     .col-lg-5 {
//       .login-box {
//         table {
//           tbody {
//             tr {
//               td {
//                 p {
//                   display:                 block;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 991px) {
  .login-page {
    padding:                               30px 20px 10px;

    .col-lg-7 {
      order:                               2;
    }
    .col-lg-5 {
      order:                               1;
      justify-content:                     flex-start;
      padding:                             0;

      .login-box {
        width:                             100%;

        h5 {
          text-align:                      center;
        }

        img {
          display:                         block;
          width:                           160px;
          margin:                          0 auto;
        }
      }

      .powered-box {
        display:                           none;
      }
    }
  }
}
</style>

<script>
import UserMfaRegisterOffcanvas from '../account/user/user-mfa/UserMfaRegisterOffcanvas.vue'

export default {
  name: "auth-mfa",
  components: {
    UserMfaRegisterOffcanvas
  },
  data: () => ({
    loading: false,
    mfaOptions: [],
    appVersion: process.env.VUE_APP_VERSION,
  }),
  created() {
    try {
      let data = this.$utils.parseDataQuery(this.$route.query.data);
      console.log("OU YEAH", data);
      this.mfaOptions = data.mfaOptions;
    } catch (err) {
      console.log("ops...", err);
    }
  },
  methods: {
    async sendMFACode(type) {
      let response = await this.$userService.sendMFACode(type)
      const transactionCode = response.transactionCode

      const enterConfirmationCodeMessage = this.$i18n.t('mfa.enterConfirmationCode')

      const ok = await this.$refs.userMfaRegisterOffcanvas.show({
        title: 'MFA',
        text: enterConfirmationCodeMessage,
        inputLabel: this.$i18n.t('mfa.code'),
        target: 'code',
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-success',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        const confirmationCode = ok
        await this.$refs.userMfaRegisterOffcanvas.close()

        try {
          response = await this.$userService.validateMFACode(type, transactionCode, confirmationCode)
          this.$authService.sessionChanged(response.sessionInfo)
          console.log(response)
          this.$router.push('/exam')
        } catch(err) {
          this.$message.popup(this, err.message , 'Ouch...', 'error')
        }
      }
    },
  },
};
</script>
