<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class=""></div>
        </div>

        <div class="col-lg-5 flex-between">
          <div class=""></div>

          <div class="login-box">
            <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="">
            <div>
              <h5>{{ $t('externalPatient.helloPatient') }}</h5>

              <h3>{{ $t('externalPatient.fillDetails') }}</h3>

              <form class="">
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="identifier" :placeholder="$t('externalPatient.accessCode')" v-model="username" data-test="inputPatientAccessCode">
                  <label for="identifier">{{ $t('externalPatient.accessCode') }}</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="password" class="form-control" id="password" :placeholder="$t('externalPatient.accessPassword')"
                         v-model="password" data-test="inputPatientPassword" @keyup.enter="doLogin()">
                  <label for="password">{{ $t('externalPatient.accessPassword') }}</label>
                  <button class="btn-icon" type="button" name="button" data-test="btnShowPassword" @click="showPassword">
                    <IconShowPassword/>
                  </button>
                </div>

                <div>
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onAccessVerify"
                    @expired="onExpired"
                    :sitekey=sitekey
                    size="invisible"
                    :load-recaptcha-script="true">
                  </vue-recaptcha>
                  <button class="btn btn-success" type="button" name="button" data-test="btnLogin" @click="doLogin()">{{ $t('login.login') }}</button>
                </div>
              </form>
            </div>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <img class="aws-logo" src="@/assets/images/erjart/logo_aws.svg" alt="Logo AWS">
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="login">
    <AuthHeader></AuthHeader>

    <div class="login-form ease-all">
      <form class="">
        <div>
          <input class="form-control ease-all" type="text" name="username" :placeholder="$t('externalPatient.accessCode')" v-model="username" data-test="inputPatientAccessCode">
          <div class="">
            <input class="form-control ease-all" type="password" name="password" :placeholder="$t('externalPatient.accessPassword')" ref="password" v-model="password" data-test="inputPatientPassword" @keyup.enter="doLogin()">
            <p class="alert" :class="alert.class" v-if="alert.message">{{ alert.message }}</p>
          </div>

          <div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onAccessVerify"
              @expired="onExpired"
              :sitekey=sitekey
              size="invisible"
              :load-recaptcha-script="true">
            </vue-recaptcha>
            <button class="btn btn-default btn-green" type="button" name="button" data-test="btnLogin" @click="doLogin()">{{ $t('login.login') }}</button>
          </div>
        </div>
      </form>
    </div>

    <AuthFooter></AuthFooter>
  </div> -->
</template>

<style lang="scss" scoped>
  .login-page {
    padding:                                 8px;
    height:                                  100vh;
    .container {
      height:                                100%;
      max-width:                             unset !important;
    }
    .row {
      height:                                100%;
    }
    .col-lg-7 {
      // @include                               border_radius(10px);
      // display:                               flex;
      // flex-direction:                        column;
      // justify-content:                       space-between;
      // background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
      // padding:                               5rem 3rem 3rem;
      div {
        @include                            border_radius(10px);
        display:                            flex;
        flex-direction:                     column;
        justify-content:                    flex-start;
        align-items:                        center;
        background:                         linear-gradient(252deg, #000 0%, #0D4636 100%);
        background:                         url('@/assets/images/phelcom/external_access.png') no-repeat center top 0%, linear-gradient(252deg, #000 0%, #0D4636 100%);
        background-size:                    cover;
        padding:                            5rem 3rem 3rem;
        height:                             100%;
      }
    }

    .col-lg-5 {
      flex-direction:                        column;
      .login-box {
        width:                               70%;
        > div {
          display:                           flex;
          flex-direction:                    column;
          gap:                               30px;
          margin-top:                        30px;
        }
        h5,
        h3 {
          color:                             var(--Brand-Primary, #1B294A);
          font-weight:                       300;
          line-height:                       normal;
          margin:                            0;
        }
        h5 {
          font-size:                         38px;
          margin:                            0;
        }
        h3 {
          font-size:                         var(--font-size-22);
        }
        .btn {
          width:                             100%;
        }
      }
      .powered-box {
        display:                             flex;
        justify-content:                     flex-end !important;
        align-items:                         center;
        p {
          color:                             var(--Brand-Primary, #1B294A);
          font-size:                         var(--font-size-10);
          font-weight:                       400;
          letter-spacing:                    2.15px;
          margin:                            0;
        }
        .logo {
          color:                             #1B294A;
          width:                             101px;
          // font-size:                         1.25em;
          margin:                            0;
          text-decoration:                   none;
        }
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1600px) {
    .login-page {
      .btn {
        padding:                             16px;
      }
      .col-lg-5 {
        .login-box {
          width:                             350px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .login-page {
      padding:                               30px 20px 10px;

      .col-lg-7 {
        order:                               2;
      }
      .col-lg-5 {
        order:                               1;
        justify-content:                     flex-start;
        padding:                             0;

        .login-box {
          width:                             100%;

          h5 {
            font-size:                       32px;
            // text-align:                      center;
          }

          h3 {
            font-size:                       18px;
          }

          img {
            display:                         block;
            width:                           160px;
            margin:                          0 auto;
          }
        }

        .powered-box {
          display:                           none;
        }
      }
    }
  }
</style>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'external-access',
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null // TODO REFACTOR change to 'type' instead
    },
    username: null,
    password: null,
    timezone: null,
    sitekey: process.env.VUE_APP_SITE_KEY,
    appVersion: process.env.VUE_APP_VERSION,
  }),
  mounted() {
    if (this.$route.query._data) {
      this.username = this.$route.query._data
      //this.$refs.password.focus()
    }
    if (this.$route.query._data2) {
      this.password = this.$route.query._data2
      //this.$refs.password.focus()
    }

  },
  methods: {
    onAccessVerify: async function (gtoken) {
      try {
        this.$refs.recaptcha.reset();
        this.alert.message = null;
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let params = {
          DEFAULT_TZ: this.timezone,
          username: this.username,
          password: this.password,
          recaptcha: gtoken
        }

        this.loading = true

        let sessionInfo = await this.$externalService.login(params)
        console.log("sessionInfo >>>", sessionInfo)
        setTimeout(() => {
          this.loading = false
        }, 500)

        // this.allowSynchronization = sessionInfo.patient
        // if (!this.allowSynchronization) {
        //   this.$router.push("/first-access");
        // } else {
        this.$router.push("/external/terms");
        // }
      } catch(err) {
        console.log("OUCH >>>>", err)
        this.$message.popup(this, 'login.wrongData', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'login.wrongData', 'danger')
      }

    },
    doLogin: async function() {
      if (!this.username || !this.password) {
        this.$message.popup(this, 'login.emptyData', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'login.emptyData', 'danger')
        return
      }
      this.$refs.recaptcha.execute();
    },
    onExpired: function () {
      console.log('Recaptcha Expired')
      this.$refs.recaptcha.reset();
    },
    showPassword() {
      let password = document.getElementById('password')
      if (password.type == 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    }
  }
}

</script>
